import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { useModal } from "~src/shared/modals/modal-context";
import { callRequest } from "~src/shared/requests/useRequest";
import {
  ISegmentTrackEvent,
  ISegmentTrackPage,
  useAnalytics,
} from "~src/shared/thirdParties/segment";
import { unauthedRequests } from "~src/unauthed/requests";

import { VerifyRecoveryCodeModalPresentation } from "./index.presentation";

interface IProps {
  challengeID: string;
  email: string;
  onLoginSuccess: () => Promise<void>;
  incrLoginAttempts: () => void;
}

export const VerifyRecoveryCodeModal: React.FC<IProps> = ({
  challengeID,
  email,
  onLoginSuccess,
  incrLoginAttempts,
}) => {
  const { trackEvent, trackPage } = useAnalytics();
  const { clearStackAndCloseModal } = useModal();
  const [submitError, setSubmitError] = useState<string | null>("");

  // Log modal impression to Segment
  useEffect(() => {
    trackPage(ISegmentTrackPage.SignInVerifyRecoveryCodeModalImpression);
  }, [trackPage]);

  const onSubmit = async (recoveryCode: string) => {
    // Log the submit code click event to Segment
    trackEvent(ISegmentTrackEvent.SignInVerifyRecoveryCodeModalContinueButtonClick);

    try {
      const res = await callRequest(
        unauthedRequests.verifyTwoFactor({ recoveryCode, challengeID, email }),
        {
          handleRPCError: (err) => {
            if (err.errorMessage !== null && err.errorMessage !== "") {
              // Log wrong totp code error to Segment
              trackEvent(ISegmentTrackEvent.SignInVerifyRecoveryCodeModalWrongCodeError);
              setSubmitError(err.errorMessage);
              incrLoginAttempts();
              return true;
            }
          },
        },
      );
      if (res.ok) {
        // Log recovery code success to Segment
        trackEvent(ISegmentTrackEvent.SignInVerifyRecoveryCodeModalSuccess);
        await onLoginSuccess();
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <VerifyRecoveryCodeModalPresentation
      onClose={clearStackAndCloseModal}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
};
