import styled from "@emotion/styled";
import { useFormik } from "formik";
import React from "react";
import { FaLock } from "react-icons/fa";
import * as yup from "yup";

import { OldInputWithLabelDontUse } from "~src/designSystem/deprecated/InputWithLabel";

interface ICodeVerificationFormProps {
  footer?: (form: ReturnType<typeof useFormik>) => React.ReactNode;
  onSubmit: (totpCode: string) => Promise<void>;
}

export const CodeVerificationForm: React.FC<ICodeVerificationFormProps> = ({
  onSubmit,
  footer,
}) => {
  const validationSchema: yup.SchemaOf<{ totpCode: string }> = yup.object({
    totpCode: yup
      .string()
      .required("Verification code is required.")
      .min(6, "Verification code must be 6 numeric digits")
      .max(6, "Verification code must be 6 numeric digits"),
  });
  const form = useFormik({
    initialValues: {
      totpCode: "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await onSubmit(values.totpCode);
    },
  });

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    form.setFieldValue(name, value);
    if (value.length === 6) {
      form.setSubmitting(true);
      await onSubmit(value);
      form.setSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        form.handleSubmit();
      }}
    >
      <FormFields>
        <OldInputWithLabelDontUse
          autoFocus
          data-private
          key="totpCode"
          type="text"
          label="Verification Code"
          value={form.values.totpCode}
          rightIcon={form.errors.totpCode !== undefined ? null : <FaLock />}
          id="totpCode"
          name="totpCode"
          error={form.errors.totpCode}
          onChange={handleChange}
          onBlur={form.handleBlur}
        />
      </FormFields>
      {footer?.(form)}
    </form>
  );
};

const FormFields = styled.div`
  display: grid;
  grid-row-gap: 20px;
`;
