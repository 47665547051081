/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import FocusTrap from "focus-trap-react";
import React from "react";
import { FaArrowLeft, FaTimes } from "react-icons/fa";

import { PipeBlack } from "~src/designSystem/icons/logos/PipeBlack";
import { PipeWhite } from "~src/designSystem/icons/logos/PipeWhite";
import { EncryptionMessage } from "~src/shared/informationals/EncryptionMessage";

export type IPipeModalProps = {
  children?: React.ReactNode;
  description?: React.ReactNode;
  hasEncryptionFooter?: boolean;
  title?: string;
  onBack?: () => void;
  /** If omitted, close modal button will be hidden. */
  onClose?: () => void;
  hideLogo?: boolean;
  dynamicHeight?: boolean;
  padding?: string;
  /**
   * If passed, the modal will not trap focus.
   */
  dontTrap?: boolean;
};

export const PipeModal: React.FC<IPipeModalProps> = ({ dontTrap = false, ...props }) => {
  if (dontTrap) {
    return <InnerModal {...props} />;
  }

  return (
    <FocusTrap>
      <InnerModal {...props} />
    </FocusTrap>
  );
};

type IInnerModalProps = Omit<IPipeModalProps, "dontTrap">;

const InnerModal: React.FC<IInnerModalProps> = ({
  children,
  description,
  hasEncryptionFooter,
  title,
  onBack,
  onClose,
  hideLogo,
  dynamicHeight = false,
  padding,
}) => {
  const theme = useTheme();

  return (
    <Wrapper padding={padding} dynamicHeight={dynamicHeight}>
      {hideLogo !== true && (
        <ImageWrapper>
          <div>{theme.isDark === true ? <PipeWhite /> : <PipeBlack />}</div>
        </ImageWrapper>
      )}
      <Heading>
        {onBack ? <FaArrowLeft onClick={onBack} /> : <div />}
        {onClose ? <FaTimes onClick={onClose} /> : <div />}
      </Heading>
      {title !== undefined && <Title>{title}</Title>}
      {description !== undefined && <Description>{description}</Description>}
      {children}
      {hasEncryptionFooter === true && (
        <EncryptionMessage
          css={css`
            position: absolute;
            bottom: 25px;
          `}
        />
      )}
    </Wrapper>
  );
};

const Title = styled.h2`
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;
  margin-top: 34px;
  margin-bottom: 9px;
  color: ${(props) => props.theme.components.ModalPipe.title};
`;

const Description = styled.p`
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: ${(props) => props.theme.components.ModalPipe.description};
  margin-bottom: 34px;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  svg {
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 14px;

  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  svg {
    height: 22px;
    width: 22px;
  }
`;

const Wrapper = styled.div<{ dynamicHeight: boolean; padding?: string }>`
  position: relative;
  width: 379px;
  height: ${(props) => props.dynamicHeight || "645px"};
  padding: ${(props) => props.padding ?? "26px"};

  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);

  background-color: ${(props) => props.theme.components.ModalPipe.background};

  svg {
    color: ${(props) => props.theme.components.ModalPipe.logo};
  }
`;
