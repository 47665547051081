import { NextPage } from "next";
import React from "react";

import { PublicAuth } from "~src/shared/pageWrappers/PublicAuth";
import { Login } from "~src/unauthed/login";

const LoginIndex: NextPage = () => {
  return <Login />;
};

export default PublicAuth(LoginIndex);
