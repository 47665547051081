import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useFormikContext } from "formik";
import { motion, useAnimation, Variants } from "framer-motion";
import Link from "next/link";
import React from "react";

import { OldFullWidthContainerDontUse } from "~src/designSystem/deprecated/FullWidthContainer";
import { OldHeadingUseTextInstead } from "~src/designSystem/deprecated/Heading";
import { OldInputWithLabelDontUse } from "~src/designSystem/deprecated/InputWithLabel";
import { OldButtonTryToAvoid } from "~src/designSystem/deprecated/OldButton";
import { OldInputGoUseTextfield } from "~src/designSystem/deprecated/OldInput";
import { ReallyOldParagraphDontUse } from "~src/designSystem/deprecated/Paragraph.old";
import { Gridbox } from "~src/designSystem/layout/Gridbox";
import { LogoProgressLoader } from "~src/designSystem/loading/LogoProgressLoader";
import { SUPPORT_EMAIL, SUPPORT_EMAIL_TEMPLATE_HREF } from "~src/shared/constants";
import { WarningMessage } from "~src/shared/informationals/WarningMessage";
import { AuthPageLayout } from "~src/shared/layouts/AuthPageLayout";

import { ILoginForm } from "./types";

export type ILoginPresentationProps = {
  /** Animation controller for login */
  animationControl: ReturnType<typeof useAnimation>;
  theme: Pick<ReturnType<typeof useTheme>, "isDark">;
  showLogoSpinner: boolean;
  /** Controls whether logo progress loader is in darkmode. */
  isGoingDark: boolean;
  isLockedOut: boolean;
  submitError: string | null;
  trackForgotPasswordClick: () => void;
  trackNewAccountClick: () => void;
  trackLoginButtonClick: () => void;
};

export const LoginPresentation: React.FC<ILoginPresentationProps> = ({
  theme,
  showLogoSpinner,
  animationControl,
  isGoingDark,
  isLockedOut,
  submitError,
  trackForgotPasswordClick,
  trackNewAccountClick,
  trackLoginButtonClick,
}) => {
  const formik = useFormikContext<ILoginForm>();
  return (
    <>
      <FullscreenContainer>
        {showLogoSpinner && (
          <>
            <LogoLoadingContainer
              initial="hidden"
              animate={animationControl}
              variants={logoSlideIn}
            >
              <LogoProgressLoader isDark={isGoingDark || theme.isDark} />
            </LogoLoadingContainer>
          </>
        )}
      </FullscreenContainer>
      <AuthPageLayout title="Pipe | Login">
        {isLockedOut ? (
          <>
            <ReallyOldParagraphDontUse margin="0 0 20px">
              We noticed several failed login attempts on your account. As a precautionary measure,
              we have invalidated your password. Please check your email for a password reset link.
            </ReallyOldParagraphDontUse>
            <ReallyOldParagraphDontUse>
              If you are unable to access your account, please contact{" "}
              <a href={SUPPORT_EMAIL_TEMPLATE_HREF}>{SUPPORT_EMAIL}</a>.
            </ReallyOldParagraphDontUse>
          </>
        ) : (
          <>
            <OldHeadingUseTextInstead size="20px">Welcome to Pipe</OldHeadingUseTextInstead>
            <OldFullWidthContainerDontUse
              orientation="row"
              style={{ marginBottom: "20px", marginTop: "10px" }}
            >
              Don’t have an account?&nbsp;
              <Link href="/signup">
                <a onClick={trackNewAccountClick}>Click here</a>
              </Link>
            </OldFullWidthContainerDontUse>
            <form onSubmit={formik.handleSubmit}>
              <WarningMessage type="error" message={submitError} />
              <Gridbox columns={1}>
                <OldInputWithLabelDontUse
                  Input={OldInputGoUseTextfield}
                  label="Email Address"
                  name="email"
                  value={formik.values.email}
                  error={formik.touched.email === true ? formik.errors.email : undefined}
                  onChange={formik.handleChange}
                  autoFocus
                />
                <OldInputWithLabelDontUse
                  Input={OldInputGoUseTextfield}
                  label="Password"
                  name="password"
                  type="password"
                  value={formik.values.password}
                  error={formik.touched.password === true ? formik.errors.password : undefined}
                  onChange={formik.handleChange}
                />
              </Gridbox>
              <OldButtonTryToAvoid
                isLoading={formik.isSubmitting}
                onClick={trackLoginButtonClick}
                type="submit"
                oldMarginTop
                primary
                style={{ height: "48px", width: "40%" }}
              >
                Login
              </OldButtonTryToAvoid>
              <OldFullWidthContainerDontUse
                orientation="row"
                style={{ marginTop: "30px", justifyContent: "left" }}
              >
                Forgot your password?&nbsp;
                <Link href="/password-reset">
                  <a onClick={trackForgotPasswordClick}>Click here</a>
                </Link>
              </OldFullWidthContainerDontUse>
            </form>
          </>
        )}
      </AuthPageLayout>
    </>
  );
};

const logoSlideIn: Variants = {
  login: { x: 0, opacity: [0, 1, 1, 1], transition: { duration: 0.8 } },
  exit: { opacity: 0, scale: 0.5, transition: { delay: 1 } },
  hidden: { opacity: 0 },
};

const LogoLoadingContainer = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: ${(props) => props.theme.components.App.background};
`;

const FullscreenContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  pointer-events: none;
`;
