import styled from "@emotion/styled";
import { useFormik } from "formik";
import React from "react";
import { FaLock } from "react-icons/fa";
import * as yup from "yup";

import { OldInputWithLabelDontUse } from "~src/designSystem/deprecated/InputWithLabel";

interface ICodeVerificationFormProps {
  footer?: (form: ReturnType<typeof useFormik>) => React.ReactNode;
  onSubmit: (recoveryCode: string) => Promise<void>;
}

export const CodeVerificationForm: React.FC<ICodeVerificationFormProps> = ({
  onSubmit,
  footer,
}) => {
  const validationSchema: yup.SchemaOf<{ recoveryCode: string }> = yup.object({
    recoveryCode: yup.string().required("Recovery code is required."),
  });
  const form = useFormik({
    initialValues: {
      recoveryCode: "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await onSubmit(values.recoveryCode);
    },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        form.handleSubmit();
      }}
    >
      <FormFields>
        <OldInputWithLabelDontUse
          data-private
          key="recoveryCode"
          type="text"
          label="Recovery Code"
          value={form.values.recoveryCode}
          rightIcon={form.errors.recoveryCode !== undefined ? null : <FaLock />}
          id="recoveryCode"
          name="recoveryCode"
          error={form.errors.recoveryCode}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
        />
      </FormFields>
      {footer?.(form)}
    </form>
  );
};

const FormFields = styled.div`
  display: grid;
  grid-row-gap: 20px;
`;
