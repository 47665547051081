import styled from "@emotion/styled";
import clsx from "clsx";
import React from "react";
import { FaLock } from "react-icons/fa";

interface IProps {
  className?: string;
}

export const EncryptionMessage = styled(({ className }: IProps) => {
  return (
    <EncryptionMessageWrapper className={clsx("encryption-message", className)}>
      <FaLock />
      <span>256-Bit Bank-Level Encryption</span>
    </EncryptionMessageWrapper>
  );
})``;

const EncryptionMessageWrapper = styled.div`
  width: 100%;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 4px;
  }
  font-size: 12px;
  color: ${(props) => props.theme.components.EncryptionMessage.text};
`;
