import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { useModal } from "~src/shared/modals/modal-context";
import { callRequest } from "~src/shared/requests/useRequest";
import {
  ISegmentTrackEvent,
  ISegmentTrackPage,
  useAnalytics,
} from "~src/shared/thirdParties/segment";
import { unauthedRequests } from "~src/unauthed/requests";

import { VerifyRecoveryCodeModal } from "../VerifyRecoveryCodeModal";
import { VerifyTotpCodeModalPresentation } from "./index.presentation";

export interface IVerifyTotpCodeModalProps {
  challengeID: string;
  email: string;
  onLoginSuccess: () => Promise<void>;
  incrLoginAttempts: () => void;
}

export const VerifyTotpCodeModal: React.FC<IVerifyTotpCodeModalProps> = ({
  challengeID,
  email,
  onLoginSuccess,
  incrLoginAttempts,
}) => {
  const { addAndOpenModal, removeTopOfStack, clearStackAndCloseModal } = useModal();

  const { trackEvent, trackPage } = useAnalytics();

  const [submitError, setSubmitError] = useState<string | null>("");

  // Log modal impression to Segment
  useEffect(() => {
    trackPage(ISegmentTrackPage.SignInVerifyTOTPCodeModalImpression);
  }, [trackPage]);

  const onSubmit = async (totpCode: string) => {
    // Log the submit code click event to Segment
    trackEvent(ISegmentTrackEvent.SignInVerifyTOTPCodeModalContinueButtonClick);

    try {
      const res = await callRequest(
        unauthedRequests.verifyTwoFactor({ totpCode, challengeID, email }),
        {
          handleRPCError: (err) => {
            if ((err.errorMessage ?? "") !== "") {
              // Log wrong totp code error to Segment
              trackEvent(ISegmentTrackEvent.SignInVerifyTOTPCodeModalWrongCodeError);

              setSubmitError(err.errorMessage);
              incrLoginAttempts();
              return true;
            }
          },
        },
      );
      if (res.ok) {
        // Log totp code success to Segment
        trackEvent(ISegmentTrackEvent.SignInVerifyTOTPCodeModalSuccess);
        await onLoginSuccess();
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const calloutOnClick = () => {
    // Log the callout click event to Segment
    trackEvent(ISegmentTrackEvent.SignInVerifyTOTPCodeCantAccessDeviceButtonClick);

    removeTopOfStack();
    addAndOpenModal({
      component: (
        <VerifyRecoveryCodeModal
          onLoginSuccess={onLoginSuccess}
          challengeID={challengeID}
          email={email}
          incrLoginAttempts={incrLoginAttempts}
        />
      ),
      config: {
        isCloseButtonHidden: true,
        isCloseOnOverlayClickDisabled: true,
        width: "fit-content",
        zIndex: "10000",
      },
    });
  };

  return (
    <VerifyTotpCodeModalPresentation
      submitError={submitError}
      onClickCallout={calloutOnClick}
      onClose={clearStackAndCloseModal}
      onSubmit={onSubmit}
    />
  );
};
